import React, { useEffect, useState } from 'react';
import './Consultations.scss';

import { Outlet } from 'react-router-dom';
import { ConsultationsSidebar } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getConsults } from '../../store/reducers/ConsultReducer';
import { useNavigate, useParams } from 'react-router-dom';

const Consultations = () => {
	const [consults, setConsults] = useState([]);
	const { consultations, loading, queryParams } = useSelector(state => state.consult);
	const params = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getConsults({ queryParams }));
	}, [queryParams]);

	useEffect(() => {
		console.log('params: ', params);
	}, [params]);

	useEffect(() => {
		const sortedConsults = [...consultations].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
		setConsults(sortedConsults);
	}, [consultations]);

	useEffect(() => {
		if (consults && consults.length > 0) {
			if (params.id) {
				navigate(`/consultations/${params.id}`);
			} else {
				navigate(`/consultations/${consults[0].id}`);
			}
		}
	}, [consults]);

	return (
		<>
			<ConsultationsSidebar consultations={consultations} />
			<Outlet />
		</>
	);
};

export default Consultations;
