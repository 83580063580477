import { createSlice } from '@reduxjs/toolkit';
import { editPracticeInfo, getPracticeInfo } from '../../reducers/PracticeInfoReducer';

const initialState = {
	loading: false,
	practiceInfo: {},
	error: null,
	success: false
};

const practiceInfoSlice = createSlice({
	name: 'practiceInfo',
	initialState,
	reducers: {},
	extraReducers: builder => {

		// get practice info

		builder.addCase(getPracticeInfo.pending, state => {
			state.loading = true;
		});
		builder.addCase(getPracticeInfo.fulfilled, (state, action) => {
			state.loading = false;
			state.practiceInfo = action.payload.data;
			state.error = false;
		});
		builder.addCase(getPracticeInfo.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//edit practice info

		builder.addCase(editPracticeInfo.pending, state => {
			state.loading = true;
		});
		builder.addCase(editPracticeInfo.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(editPracticeInfo.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});
	}
});

export default practiceInfoSlice.reducer;
