import React, { useEffect } from 'react';
import './Login.scss';

import Icon from '../../Icon';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin } from '../../store/reducers/AuthReduser';
import toast, { Toaster } from 'react-hot-toast';

const AdminLogin = () => {
	const user = JSON.parse(localStorage.getItem('user'));
	const { loading, error, success, userInfo } = useSelector(state => state.auth);
	const userInfoState = useSelector(state => state.auth.userInfo);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onFormSubmit = async event => {
		event.preventDefault();
		const data = {
			email: event.target.elements.email.value,
			password: event.target.elements.password.value
		};
		toast.loading('Loading...');

		dispatch(adminLogin(data)).then(() => {
			toast.dismiss();
			setTimeout(() => {
				if (user && user.role === 0) {
					navigate('/info/practice-information');
				} else if (user && user.role === 1) {
					navigate('/consultations');
				}
			}, 1000);
		});
	};

	useEffect(() => {
		if (user && user.role === 0) {
			toast.dismiss();
			navigate('/info/practice-information');
		} else if (user && user.role === 1) {
			toast.dismiss();
			navigate('/consultations');
		}
	}, [user]);

	// if(userInfoState.hasOwnProperty('email')) {
	// 	navigate('/info/practice-information');
	// }

	// useEffect(() => {
	// 	if(user) {
	// 		navigate('/info/practice-information');
	// 	}
	// }, [user])

	useEffect(() => {
		if (error) {
			toast.error('Invalid email or password');
		}
	}, [error]);

	useEffect(() => {
		toast.dismiss();
	}, []);

	return (
		<>
			<Toaster />
			<form action="" className="login-form" onSubmit={onFormSubmit}>
				<h2 className="login-form-title">LOGIN</h2>
				<input type="email" name="email" className="login-form-input" placeholder="Email" />
				<input type="password" name="password" className="login-form-input" placeholder="Password" />
				<button type="submit" className="login-form-button">
					<div className="empty"></div>
					<span>Sign In</span>
					<Icon name="sign-in" styles={{ fill: '#26A4ED', width: '40' }} />
				</button>
			</form>
		</>
	);
};

export default AdminLogin;
