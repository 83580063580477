import React, { useEffect } from 'react';
import './Login.scss';
import Icon from '../../Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientLogin, logout } from '../../store/reducers/AuthReduser';
import toast, { Toaster } from 'react-hot-toast';

const ClientLogin = () => {
	const { loading, error, success, userInfo } = useSelector(state => state.auth);
	const user = JSON.parse(localStorage.getItem('user'));
	// const userInfoState = useSelector(state => state.auth.userInfo);
	const { p, id } = useParams();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onFormSubmit = async event => {
		event.preventDefault();
		const data = {
			p: p,
			id: id,
			lastName: event.target.elements.lastName.value,
			patientNumber: event.target.elements.patientNumber.value
		};

		dispatch(clientLogin(data)).then(() => {
			toast.loading('Loading...');
			localStorage.setItem('p', JSON.stringify(p))
			setTimeout(() => {
				if (user) {
					toast.dismiss();
					if(user.status === 1) {
						navigate(`/present/${id}/${user.createdAt}/${user.treatmentId}`)
					}
					else if(user.status === 2) {
						navigate(`/contract/${id}/${user.createdAt}/${user.treatmentId}`)
					}
					if(user.status === 3) {
						navigate(`/payment/${id}/${user.createdAt}/${user.treatmentId}`)
					}
					if(user.status === 4) {
						navigate(`/monthly-payment/${id}/${user.createdAt}/${user.treatmentId}`)
					}
					if(user.status === 5) {
						navigate(`/patient-profile/${id}/${user.createdAt}/${user.treatmentId}`)
					}
					// navigate('/info/practice-information');
				}
			}, 1000);
		});
	};

	useEffect(() => {
		if (user && user.role !== 2) {
			dispatch(logout());
		} else {
			// if (user) {
			// 	toast.dismiss();
			// 	if(user.status === 1) {
			// 		navigate(`/present/${id}/${user.createdAt}/${user.treatmentId}`)
			// 	}
			// 	else if(user.status === 2) {
			// 		navigate(`/contract/${id}/${user.createdAt}/${user.treatmentId}`)
			// 	}
			// 	if(user.status === 3) {
			// 		navigate(`/payment/${id}/${user.createdAt}/${user.treatmentId}`)
			// 	}
			// 	if(user.status === 4) {
			// 		navigate(`/monthly-payment/${id}/${user.createdAt}/${user.treatmentId}`)
			// 	}
			// 	// navigate('/info/practice-information');
			// }
		}
	}, []);

	return (
		<>
			<Toaster />
			<form action="" className="login-form" onSubmit={onFormSubmit}>
				<h2 className="login-form-title">LOGIN</h2>
				<input type="text" className="login-form-input" placeholder="Patient Last Name" name="lastName" />
				<input type="text" className="login-form-input" placeholder="Patient ID" name="patientNumber" />
				<button type="submit" className="login-form-button">
					<div className="empty"></div>
					<span>Log In</span>
					<Icon name="sign-in" styles={{ fill: '#26A4ED', width: '40' }} />
				</button>
			</form>
		</>
	);
};

export default ClientLogin;
