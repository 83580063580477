import React from 'react'
import './Loader.scss'

import { TailSpin } from 'react-loader-spinner'

const Loader = () => {
  return (
	<div className='loader'><TailSpin
	visible={true}
	height="80"
	width="80"
	color="#26a4ed"
	ariaLabel="tail-spin-loading"
	radius="1"
	wrapperStyle={{}}
	wrapperClass=""
	/></div>
  )
}

export default Loader