import { createSlice } from '@reduxjs/toolkit';
import { createDiscounts, deleteDisconts, editDiscounts, getDiscounts } from '../../reducers/PracticeInfoReducer';

const initialState = {
	loading: false,
	discounts: [],
	error: null,
	success: false
};

const discountsSlice = createSlice({
	name: 'disconts',
	initialState,
	reducers: {},
	extraReducers: builder => {

		// get discounts

		builder.addCase(getDiscounts.pending, state => {
			state.loading = true;
		});
		builder.addCase(getDiscounts.fulfilled, (state, action) => {
			state.loading = false;
			state.discounts = action.payload.data.list;
			state.error = false;
		});
		builder.addCase(getDiscounts.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// create discounts

		builder.addCase(createDiscounts.pending, state => {
			state.loading = true;
		});
		builder.addCase(createDiscounts.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(createDiscounts.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// edit discounts

		builder.addCase(editDiscounts.pending, state => {
			state.loading = true;
		});
		builder.addCase(editDiscounts.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(editDiscounts.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// delete discounts
		
		builder.addCase(deleteDisconts.pending, state => {
			state.loading = true;
		});
		builder.addCase(deleteDisconts.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(deleteDisconts.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});
	}
});

export default discountsSlice.reducer;
