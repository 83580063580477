import React from 'react'
import './Payment.scss'
import Icon from '../../Icon'

const CancelPayment = () => {
  return (
    <div className="success-wrapper">
    <div className="success-icon">
      <Icon name="payment error"></Icon>
    </div>
    <h2 className="success-header">Payment Error</h2> 
    <div className="success-buttons-wrapper">
      <button className="success-next-button">Next</button>
    </div>
  </div>
  )
}

export default CancelPayment