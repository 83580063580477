import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { refresh } from './AuthReduser';

export const getPresent = createAsyncThunk('present', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(`https://api.fcform.shop/api/presentation?patientId=${data.id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getPresentById = createAsyncThunk('present/byId', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(
			`https://api.fcform.shop/api/presentation/by-id?id=${data.treatmentId}&patientId=${data.id}&clientDate=${data.date}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const patchTreatments = createAsyncThunk('present/patch-treatments', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.patch(
			`https://api.fcform.shop/api/presentation`,
			{ item: { ...data } },
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getContract = createAsyncThunk('present/contract', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(
			`https://api.fcform.shop/api/presentation/by-id?id=${data.treatmentId}&patientId=${data.id}&clientDate=${data.date}/${data.contractUrl}`,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const share = createAsyncThunk('present/share', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/presentation/share`,
			{
				...data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const stillThinking = createAsyncThunk('present/still-thinking', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/presentation/still-thinking?patientId=${data.id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const postEnabled = createAsyncThunk('present/enabled', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/presentation/update-enabled`,
			{
				...data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const accept = createAsyncThunk('present/accept', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/presentation/accept?treatmentId=${data.treatmentId}&patientId=${data.patientId}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const signContract = createAsyncThunk('present/post-signed-contract', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const formData = new FormData();
		formData.append('file', data.file, 'document.pdf');

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/presentation/signed-contract?patientId=${data.id}&sum=${data.sum}`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getSignedContract = createAsyncThunk('present/get-signed-contract', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(`https://api.fcform.shop/api/presentation/signed-contract?patientId=${data.id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});
