import React, { useEffect, useState } from 'react';
import './MonthlyPaymentQuestion.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPresentById, stillThinking } from '../../store/reducers/PresentationReducer';
import Loader from '../Loader/Loader';
import Icon from '../../Icon';
import toast, { Toaster } from 'react-hot-toast'

const MonthlyPaymentQuestion = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { presentation, loading } = useSelector(state => state.presentation);
	const [presentationInfo, setPresentationInfo] = useState(null);
	const { id, date, treatmentId } = useParams();

	const handleYes = () => {
		navigate(`/monthly-payment-form/${id}/${date}/${treatmentId}`);
	};

	const handleShare = () => {
		toast
			.promise(dispatch(stillThinking({ id: id })), {
				loading: 'Loading',
				success: 'Email send successfuly',
				error: 'Error'
			})
	};

	useEffect(() => {
		if (!presentation) {
			dispatch(getPresentById({ id, date, treatmentId }));
		}
	}, []);

	useEffect(() => {
		setPresentationInfo(presentation);
	}, [presentation]);

	if (loading) {
		return (
			<div className="payment-wrapper">
				<div className="payment-block">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<>
			<Toaster position="top-right" />
			<div className="monthly-payment-wrapper">
				<div className="monthly-payment-block">
					<img src={presentationInfo?.organization.logo || ''} alt="avatar" width={150} height={150} style={{borderRadius: '15px'}} />
					<div className="monthly-payment-block-text">
						<p className="monthly-payment-block-title">
							Here is your monthly payment amount: $
							{presentationInfo?.calculations?.payments?.bpMonthlyPayment_calc?.toFixed(2)}
						</p>
						<p className="monthly-payment-block-details">
							Would you like to enter your account information for monthly drafts now to avoid delays on
							the day of banding? We will not draft your account until one month after the banding
							appointment.
						</p>
					</div>
					<div className="monthly-payment-buttons">
						<button className="monthly-payment-yes" onClick={handleYes}>
							Yes
						</button>
						<button className="monthly-payment-thinking" onClick={handleShare}>
							I'm still Thinking
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default MonthlyPaymentQuestion;
