import React, { useEffect, useState } from 'react';
import './Payment.scss';
import Icon from '../../Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postPaymentSuccess } from '../../store/reducers/PaymentReducer';
import Loader from '../Loader/Loader';

const SuccessPage = () => {
	const { id, date, treatmentId } = useParams();
	const { payment, loading } = useSelector(state => state.payment);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [status, setStatus] = useState(null);

	const handleNavigate = () => {
		if (payment.status && payment.status === 4) {
			navigate(`/monthly-payment/${id}/${date}/${treatmentId}`);
		} else if (payment.status && payment.status === 5) {
			navigate(`/patient-profile/${id}/${date}/${treatmentId}`)
		}
	};

	useEffect(() => {
			dispatch(postPaymentSuccess({ id }));
	}, []);

	useEffect(() => {
		console.log(payment);
	}, [payment]);

	if (loading) {
		return (
			<div className="success-wrapper">
				<Loader />
			</div>
		);
	}

	return (
		<div className="success-wrapper">
			<div className="success-icon">
				<Icon name="payment success"></Icon>
			</div>
			<h2 className="success-header">Thank you, your down payment was successful.</h2>
			<div className="success-buttons-wrapper">
				<button className="success-next-button" onClick={handleNavigate}>
					Next
				</button>
			</div>
		</div>
	);
};

export default SuccessPage;
