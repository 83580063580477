import { createSlice } from '@reduxjs/toolkit';
import { deleteConsult, getConsults, getConsultsById, postConsult, sendFaxWithContract } from '../../reducers/ConsultReducer';
// const setQueryParams = createAction('setQueryParams')
// import {  } from '../reducers/AuthReduser';

const initialState = {
	loading: false,
	consultations: [],
	consult: {},
	error: null,
	success: false,
	queryParams: {
		size: 1000,
		sortDir: 'desc',
		sortBy: 'id'
	}
};

const consultSlice = createSlice({
	name: 'consult',
	initialState,
	reducers: {
		setQueryParams: (state, action) => {
			const { locationId, status } = action.payload;
			if (locationId !== '' && !state.queryParams.hasOwnProperty(locationId)) {
				state.queryParams.locationId = locationId;
			} else {
				delete state.queryParams.locationId
			}
			if (status !== '' && !state.queryParams.hasOwnProperty(status)) {
				state.queryParams.status = status;
			} else {
				delete state.queryParams.status
			}
		}
	},
	extraReducers: builder => {
		//get consult

		builder.addCase(getConsults.pending, state => {
			state.loading = true;
		});
		builder.addCase(getConsults.fulfilled, (state, action) => {
			state.loading = false;
			state.consultations = action.payload.data.list;
			state.error = false;
		});
		builder.addCase(getConsults.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//get consult by id

		builder.addCase(getConsultsById.pending, state => {
			state.loading = true;
		});
		builder.addCase(getConsultsById.fulfilled, (state, action) => {
			state.loading = false;
			state.consult = action.payload.data;
			state.error = false;
		});
		builder.addCase(getConsultsById.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//create consult

		builder.addCase(postConsult.pending, state => {
			state.loading = true;
		});
		builder.addCase(postConsult.fulfilled, (state) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(postConsult.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//delete consult

		builder.addCase(deleteConsult.pending, state => {
			state.loading = true;
		});
		builder.addCase(deleteConsult.fulfilled, state => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(deleteConsult.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		builder.addCase(sendFaxWithContract.pending, state => {
			state.loading = true;
		});
		builder.addCase(sendFaxWithContract.fulfilled, state => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(sendFaxWithContract.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// set query params

		// builder.addCase(setQueryParams.pending, (state, action) => {
		// 	const { locationId, selectedValues } = action.payload;
		// 	state.queryParams.locationId = locationId;
		// 	state.queryParams.selectedValues = selectedValues;
		// });
	}
});

export const { setQueryParams } = consultSlice.actions;

export default consultSlice.reducer;
