import { createSlice } from '@reduxjs/toolkit';
import { createLocations, deleteLocations, editLocations, getLocations } from '../../reducers/PracticeInfoReducer';

const initialState = {
	loading: false,
	locations: [],
	error: null,
	success: false
};

const locationsSlice = createSlice({
	name: 'locations',
	initialState,
	reducers: {},
	extraReducers: builder => {

		// get locations

		builder.addCase(getLocations.pending, state => {
			state.loading = true;
		});
		builder.addCase(getLocations.fulfilled, (state, action) => {
			state.loading = false;
			state.locations = action.payload.data.list;
			state.error = false;
		});
		builder.addCase(getLocations.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// create locations

		builder.addCase(createLocations.pending, state => {
			state.loading = true;
		});
		builder.addCase(createLocations.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(createLocations.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// edit locations

		builder.addCase(editLocations.pending, state => {
			state.loading = true;
		});
		builder.addCase(editLocations.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(editLocations.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// delete locations

		builder.addCase(deleteLocations.pending, state => {
			state.loading = true;
		});
		builder.addCase(deleteLocations.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(deleteLocations.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});
	}
});

export default locationsSlice.reducer;
