import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { refresh } from './AuthReduser'

export const getPayment = createAsyncThunk('payment', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(`https://api.fcform.shop/api/payment?patientId=${data.id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const signMonthlyContract = createAsyncThunk('payment/post-signed-monthly-contract', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const formData = new FormData();
		formData.append('file', data.file, 'document.pdf');

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/payment/signed-orthobanc-form?patientId=${data.id}`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const postPaymentSuccess = createAsyncThunk('payment/post-payment-success', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/payment/payment-success?patientId=${data.id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getSignedMonthlyContract = createAsyncThunk('payment/get-signed-contract', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(`https://api.fcform.shop/api/payment/signed-orthobanc-form?patientId=${data.id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});
