import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { refresh } from './AuthReduser'

export const getConsults = createAsyncThunk('consult', async (queryParams, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(`https://api.fcform.shop/api/consult`, {
			params: queryParams.queryParams,
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getConsultsById = createAsyncThunk('consult/id', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(`https://api.fcform.shop/api/consult/${data.id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const postConsult = createAsyncThunk('consult/post', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(`https://api.fcform.shop/api/consult`, {...data}, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.response.data.msg;
	}
});

export const sendFaxWithContract = createAsyncThunk('consult/send-fax-contract', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(`https://api.fcform.shop/api/payment/send-contract?patientId=${data.id}`, {}, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.response.data.msg;
	}
});

export const deleteConsult = createAsyncThunk('consult/delete', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.delete(`https://api.fcform.shop/api/consult/${data.id}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

