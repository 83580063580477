import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const adminLogin = createAsyncThunk('auth/login', async data => {
	try {
		const res = await axios.post(
			`https://api.fcform.shop/api/auth/admin-login`,
			{
				email: data.email,
				password: data.password
			},
			{
				withCredentials: true,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers': 'Content-Type'
			}
		);
		localStorage.setItem('userToken', JSON.stringify(res.headers.get('Authorization')));
		console.log(JSON.stringify(res.headers.get('Authorization')));
		
		localStorage.setItem('RefreshToken', JSON.stringify(res.data.refreshToken));
		localStorage.setItem('user', JSON.stringify(res.data.user));
		localStorage.setItem('refreshTime', Date.now() + 300000);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const clientLogin = createAsyncThunk('auth/client-login', async data => {
	try {
		const res = await axios.post(
			`https://api.fcform.shop/api/auth/login?p=${data.p}&id=${data.id}`,
			{
				lastName: data.lastName,
				patientNumber: data.patientNumber
			},
			{
				withCredentials: true,
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers': 'Content-Type'
			}
		);

		localStorage.setItem('userToken', JSON.stringify(res.headers.get('Authorization')));
		localStorage.setItem('RefreshToken', JSON.stringify(res.data.refreshToken));
		localStorage.setItem('user', JSON.stringify(res.data.user));
		localStorage.setItem('refreshTime', Date.now() + 300000);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const refresh = createAsyncThunk('auth/refresh', async () => {
	try {
		const refreshToken = JSON.parse(localStorage.getItem('RefreshToken'));
		const res = await axios.post(
			`https://api.fcform.shop/api/auth/refresh`,
			{ refreshToken },
			{ withCredentials: true }
		);

		localStorage.setItem('userToken', JSON.stringify(res.headers.get('Authorization')));
		localStorage.setItem('refreshTime', Date.now() + 300000);
		localStorage.setItem('user', JSON.stringify(res.data));

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const sendCode = createAsyncThunk('auth/send-code', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.post(`https://api.fcform.shop/api/users/my-profile/send-code`, {
			user: {
				id: data.id,
				email: data.email
			}
		},
		{
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const resetPassword = createAsyncThunk('auth/reset', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.patch(`https://api.fcform.shop/api/users/my-profile`, {
			user: {...data}
		},
		{
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getUserInfo = createAsyncThunk('auth/profile', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));
		const res = await axios.get(`https://api.fcform.shop/api/users?searchBy=id&search=${data.id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const logout = createAsyncThunk('auth/logout', async () => {
	localStorage.removeItem('userToken');
	localStorage.removeItem('RefreshToken');
	localStorage.removeItem('user');
	localStorage.removeItem('refreshTime');
	localStorage.removeItem('monthlyPaymentData');
});
