import React, { useEffect, useState } from 'react';
import './PracticeInfo.scss';

import Icon from '../../Icon';
import { TagPicker } from 'rsuite';
import { cardsList, formatPhoneNumber } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { editPracticeInfo, getPracticeInfo } from '../../store/reducers/PracticeInfoReducer';
import Loader from '../Loader/Loader';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

// const practice = practices.find(practice => practice.id === 1);

const PracticeInfo = () => {
	const dispatch = useDispatch();
	const { practiceInfo, loading, error } = useSelector(state => state.practiceInfo);
	const user = JSON.parse(localStorage.getItem('user'));
	const [isEdit, setIsEdit] = useState(false);
	const [editedData, setEditedData] = useState([]);
	const [logoFile, setLogoFile] = useState(null);

	const formatDate = date => {
		if (date) {
			const d = new Date(date);
			const month = d.getMonth() + 1;
			const day = d.getDate();
			const year = d.getFullYear();
			return `${month}/${day}/${year}`;
		} else {
			return '';
		}
	};

	const onEditClick = () => {
		setIsEdit(true);
	};

	const onSaveClick = () => {
		const data = { data: editedData, file: logoFile };
		console.log(data);
		dispatch(editPracticeInfo(data)).then(() => dispatch(getPracticeInfo({ practiceId: user?.practiceId })));
		setIsEdit(false);
		setLogoFile(null);
	};

	const handleCardChange = value => {
		setEditedData({ ...editedData, creditCards: value });
	};

	const handleInputChange = (index, value) => {
		const newPhones = [...editedData.phones];
		newPhones[index] = value;
		setEditedData({ ...editedData, phones: newPhones });
	};

	const handleAddPhone = () => {
		setEditedData({ ...editedData, phones: [...editedData.phones, ''] });
	};

	const handleRemovePhone = index => {
		const newPhones = editedData.phones.filter((_, i) => i !== index);
		setEditedData({ ...editedData, phones: newPhones });
	};

	const handleLogoUpload = e => {
		const file = e.target.files[0];

		setLogoFile(file);
	};

	useEffect(() => {
		setEditedData(practiceInfo);
	}, [practiceInfo]);

	useEffect(() => {
		dispatch(getPracticeInfo({ practiceId: user?.practiceId }));
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			{loading ? (
				<></>
			) : (
				<div className="info-container">
					<div className="info-header">
						<div className="info-header-right">
							<div className="info-header-right-title">Settings</div>
						</div>
						<div className="info-header-left">
							{isEdit ? (
								<div className="info-header-left-edit" onClick={onSaveClick}>
									<Icon name="save" />
								</div>
							) : (
								<div className="info-header-left-edit" onClick={onEditClick}>
									<Icon name="edit" />
								</div>
							)}
						</div>
					</div>
					<div className="clinic-title">
						{isEdit ? (
							logoFile ? (
								<div className="image-prewiew">
									<img src={URL.createObjectURL(logoFile)} alt="logo" />
								</div>
							) : (
								<label className="input-logo-wrapper">
									<div className="input-logo-block">
										<p>Upload Your Image</p>
									</div>
									<input
										type="file"
										onChange={handleLogoUpload}
										className="input-logo"
										name="upload-image"
									/>
								</label>
							)
						) : (
							<img src={practiceInfo.logo || ''} alt="avatar" width={150} height={150} />
						)}

						<div className="clinic-title-info">
							<b className="clinic-info-string-title-gray">Practice Name:</b>
							<h3>
								{isEdit ? (
									<input
										type="text"
										value={editedData.name}
										className="edit-input-title"
										onChange={e => {
											setEditedData({ ...editedData, name: e.target.value });
										}}
									/>
								) : (
									editedData.name
								)}
							</h3>
							<b className="clinic-info-string-title-gray">Tagline:</b>
							<p>
								<b>
									{isEdit ? (
										<input
											type="text"
											value={editedData.tagLine}
											className="edit-input-title offices"
											onChange={e => {
												setEditedData({ ...editedData, tagLine: e.target.value });
											}}
										/>
									) : (
										editedData.tagLine
									)}
								</b>
							</p>
						</div>
					</div>
					<div className="cards-list">
						<b className="cards-list-title">List of Credit Cards Accepted:</b>
						<div className="cards-list-list">
							{isEdit ? (
								<>
									<TagPicker
										data={cardsList}
										value={editedData.creditCards}
										onChange={handleCardChange}
										// autoComplete="off"
									/>
								</>
							) : editedData.creditCards?.length > 0 ? (
								editedData.creditCards.map(card => <Icon key={card} name={card} />)
							) : (
								''
							)}
						</div>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">OrthoBanc OID Number:</b>
						<p className="clinic-info-string-list">
							{isEdit ? (
								<input
									type="text"
									value={editedData.oid}
									className="edit-input-content"
									onChange={e => setEditedData({ ...editedData, oid: e.target.value })}
								/>
							) : (
								practiceInfo.oid
							)}
						</p>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Phone Numbers:</b>
						<div className="clinic-info-string-details-container">
							{editedData.phones?.length > 0 &&
								editedData.phones.map((phone, index) => {
									if (isEdit) {
										return (
											<div key={index} className="edit-input-wrapper">
												<PhoneInput
													defaultCountry="us"
													value={phone}
													onChange={(info, value) => handleInputChange(index, info)}
													hideDropdown
													style={{}}
												/>
												<>
													<button
														className="edit-button"
														onClick={() => handleRemovePhone(index)}>
														-
													</button>
													{index === editedData.phones?.length - 1 && (
														<button className="edit-button" onClick={handleAddPhone}>
															+
														</button>
													)}
												</>
											</div>
										);
									} else {
										return (
											<p key={index} className="clinic-info-string-phone">
												{formatPhoneNumber(phone)}
											</p>
										);
									}
								})}
						</div>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Web-Site:</b>
						{isEdit ? (
							<input
								type="text"
								value={editedData.webSite}
								className="edit-input-content website"
								onChange={e => setEditedData({ ...editedData, webSite: e.target.value })}
							/>
						) : (
							<a className="clinic-info-string-details-website" href="/">
								{practiceInfo.webSite}
							</a>
						)}
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Maximum Payout Discount:</b>
						<div className="clinic-info-string-details">
							<p>
								{isEdit ? (
									<input
										type="text"
										value={editedData.maxPayoutDiscount}
										className="edit-input-number"
										onChange={e =>
											setEditedData({ ...editedData, maxPayoutDiscount: e.target.value })
										}
									/>
								) : (
									practiceInfo.maxPayoutDiscount
								)}
								% &nbsp;
							</p>{' '}
							of Net Patient Investment
						</div>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Payout Discount Begins at:</b>
						<div className="clinic-info-string-details">
							<p>
								{isEdit ? (
									<input
										type="text"
										value={editedData.payoutDiscountBeginsAt}
										className="edit-input-number"
										onChange={e =>
											setEditedData({ ...editedData, payoutDiscountBeginsAt: e.target.value })
										}
									/>
								) : (
									practiceInfo.payoutDiscountBeginsAt
								)}
								% &nbsp;
							</p>{' '}
							of Net Patient Investment
						</div>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Maximum APR for Extended Payout Time:</b>
						<div className="clinic-info-string-details">
							<p>
								{isEdit ? (
									<input
										type="text"
										value={editedData.maxAprForExtendedPayoutTime}
										className="edit-input-number"
										onChange={e =>
											setEditedData({
												...editedData,
												maxAprForExtendedPayoutTime: e.target.value
											})
										}
									/>
								) : (
									practiceInfo.maxAprForExtendedPayoutTime
								)}
								% &nbsp;
							</p>{' '}
							of Net Patient Investment
						</div>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Maximum Extended Payout Time:</b>
						<div className="clinic-info-string-details">
							<p>
								{isEdit ? (
									<input
										type="text"
										value={editedData.maxExtendedPayoutTime}
										className="edit-input-number"
										onChange={e =>
											setEditedData({ ...editedData, maxExtendedPayoutTime: e.target.value })
										}
									/>
								) : (
									practiceInfo.maxExtendedPayoutTime
								)}
								% &nbsp;
							</p>{' '}
							past BP Months
						</div>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Credit from Previous Contract:</b>
						<div className="clinic-info-string-details">
							<p>
								{isEdit ? (
									<input
										type="text"
										value={editedData.creditFromPrevContract}
										className="edit-input-number"
										onChange={e =>
											setEditedData({ ...editedData, creditFromPrevContract: e.target.value })
										}
									/>
								) : (
									practiceInfo.creditFromPrevContract
								)}
								% &nbsp;
							</p>{' '}
							of Previous Contract
						</div>
					</div>
					<div className="clinic-info-string">
						<b className="clinic-info-string-title">Last Updated:</b>
						<p className="clinic-info-string-date">{formatDate(practiceInfo.lastUpdated)}</p>
					</div>
				</div>
			)}
		</>
	);
};

export default PracticeInfo;
