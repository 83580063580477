import React, { useEffect, useState } from 'react';
import './AddOns.scss';

import { Checkbox, Table } from 'rsuite';
// import { addOns } from '../../data';
import Icon from '../../Icon';
import {
	CheckCell,
	EditableActiveCell,
	EditableCell,
	EditableFeesCell,
	EditableOrderCell,
	EditableVisibleCell,
	RadioCell
} from '../../utils/cells';
import toast, { Toaster } from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { createAddOns, deleteAddOns, editAddOns, getAddOns } from '../../store/reducers/PracticeInfoReducer';
import Loader from '../Loader/Loader';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const { Column, HeaderCell } = Table;

const AddOns = () => {
	const dispatch = useDispatch();
	const { error, loading, treatmentTypes, addOns } = useSelector(state => state.addOns);
	const [checkedKeys, setCheckedKeys] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [addOnsArray, setAddOnsArray] = useState([]);
	// let checked = false;
	// let indeterminate = false;

	// if (checkedKeys !== null) {
	// 	checked = true;
	// } else {
	// 	checked = false;
	// }

	// const handleCheckAll = (value, checked) => {
	// 	const keys = checked ? addOns.map(item => item.id) : [];
	// 	setCheckedKeys(keys);
	// };

	const handleCheck = value => {
		console.log('value', value);
		// const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
		if (checkedKeys === value) {
			setCheckedKeys(null);
		} else {
			setCheckedKeys(value);
		}
	};

	useEffect(() => {
		console.log('checkedKeys', checkedKeys);
	}, [checkedKeys]);

	const onEditClick = () => {
		setIsEdit(true);
		const nextData = addOnsArray.map(item => ({ ...item, status: isEdit ? null : 'EDIT' }));
		setAddOnsArray(nextData);
	};

	const onSaveClick = () => {
		// const handleSave = () => {
			setIsEdit(false);
			const sortedData = [...addOnsArray]
				.reverse()
				.map((item, index) => ({ ...item, order: index + 1 }))
				.sort((a, b) => b.order - a.order);

			const createdData = sortedData.filter(item => item.created);
			const editedData = sortedData.filter(item => !item?.created);

			const nextCreateData = createdData.map(item => {
				const { status, created, fee, ...newItem } = item;
				return {
					...newItem,
					fee: parseFloat(fee)
				};
			});

			const nextEditData = editedData.map(item => {
				const { status, created, fee, ...newItem } = item;
				return {
					...newItem,
					fee: parseFloat(fee)
				};
			});

			dispatch(editAddOns(nextEditData));
			dispatch(createAddOns(nextCreateData));
			setAddOnsArray([...nextEditData, ...nextCreateData]);
		// };

		// const handleDiscard = () => {
		// 	const sortedAddOns = [...addOns]?.sort((a, b) => b.order - a.order);
		// 	setAddOnsArray(sortedAddOns);
		// 	setIsEdit(false);
		// 	toast.dismiss();
		// };

		// toast(t => (
		// 	<span>
		// 		<b>Do you want to save your changes?</b>
		// 		<div className="save-toast">
		// 			<button
		// 				onClick={() => {
		// 					toast.dismiss(t.id);
		// 					handleSave();
		// 				}}
		// 				className="save-toast-yes">
		// 				Yes
		// 			</button>
		// 			<button onClick={() => handleDiscard()} className="save-toast-no">
		// 				No
		// 			</button>
		// 		</div>
		// 	</span>
		// ));
	};

	const onDeleteClick = () => {
		if (checkedKeys) {
			dispatch(deleteAddOns(checkedKeys));
			setCheckedKeys(null);
		}
	};

	const onAddClick = () => {
		const maxOrder = Math.max(...addOnsArray.map(item => item.order), 0);
		const newAddon = {
			treatmentId: null,
			name: '',
			fee: 0,
			status: 'EDIT',
			created: true,
			isActive: true,
			order: maxOrder + 1
		};

		const nextData = addOnsArray.map(item => ({ ...item, status: 'EDIT' }));
		const updatedAddOnsArray = [newAddon, ...nextData];
		setAddOnsArray(updatedAddOnsArray);
		if (!isEdit) {
			setIsEdit(true);
		}
	};

	const handleChange = (id, key, value) => {
		const nextData = [...addOnsArray];
		nextData.find(item => item.id === id)[key] = value;
		setAddOnsArray(nextData);
	};

	const handleDragRow = (sourceId, targetId) => {
		const draggedItem = addOnsArray.find(item => item.id === sourceId);
		const draggedIndex = addOnsArray.findIndex(item => item.id === sourceId);
		const targetIndex = addOnsArray.findIndex(item => item.id === targetId);

		if (draggedIndex === -1 || targetIndex === -1) {
			return;
		}

		const updatedArray = [...addOnsArray];

		updatedArray.splice(draggedIndex, 1);

		updatedArray.splice(targetIndex, 0, draggedItem);

		setAddOnsArray(updatedArray);
	};

	const handleActiveClick = (id, key, value) => {
		const nextData = [...addOnsArray];
		const foundItem = nextData.find(item => item.id === id);
		if (foundItem) {
			const updatedItem = { ...foundItem, [key]: value };
			const index = nextData.findIndex(item => item.id === id);
			if (index !== -1) {
				nextData[index] = updatedItem;
				setAddOnsArray(nextData);
			}
		}
	};

	useEffect(() => {
		const sortedAddOns = [...addOns]?.sort((a, b) => b.order - a.order);
		setAddOnsArray(sortedAddOns);
	}, [addOns]);

	useEffect(() => {
		dispatch(getAddOns());
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Toaster position="top-right" />
			<div className="info-container">
				<div className="info-header">
					<div className="info-header-right">
						<div className="info-header-right-title">Add-Ons</div>
					</div>
					<div className="info-header-left">
						{isEdit ? (
							<>
								<div className="info-header-left-add" onClick={onAddClick}>
									<Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
								</div>
								<div className="info-header-left-edit" onClick={onSaveClick}>
									<Icon name="save" />
								</div>
							</>
						) : (
							<>
								<div className="info-header-left-add" onClick={onAddClick}>
									<Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
								</div>
								<div className="info-header-left-edit" onClick={onEditClick}>
									<Icon name="edit" />
								</div>
								<div className="info-header-left-delete" onClick={onDeleteClick}>
									<Icon name="delete" />
								</div>
							</>
						)}
					</div>
				</div>
				{/* <div className="table-wrapper"> */}
				<DndProvider backend={HTML5Backend}>
					<Table data={isEdit ? addOnsArray : addOnsArray.filter(item => item.isActive)} autoHeight>
						<Column width={50} align="center">
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								<div>
									{/* <Checkbox
										checked={checked}
										inline
										verticalalign="middle"
										// indeterminate={indeterminate}
										onChange={handleCheckAll}
									/> */}
								</div>
							</HeaderCell>
							<RadioCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />
						</Column>
						<Column flexGrow={1} align="start" verticalalign="middle" height={50}>
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								Add-Ons
							</HeaderCell>
							<EditableCell dataKey="name" onChange={handleChange} />
						</Column>
						<Column flexGrow={2} align="center" verticalalign="middle">
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								Fees
							</HeaderCell>
							<EditableFeesCell dataKey="fee" onChange={handleChange} />
						</Column>
						{isEdit && (
							<>
								<Column flexGrow={1} align="center" verticalalign="middle">
									<HeaderCell
										style={{
											backgroundColor: '#26A4ED',
											color: 'white',
											fontWeight: 'bold',
											fontSize: 16
										}}>
										Active
									</HeaderCell>
									<EditableVisibleCell dataKey="isActive" onChange={handleActiveClick} />
								</Column>
								<Column flexGrow={1} align="center" verticalalign="middle">
									<HeaderCell
										style={{
											backgroundColor: '#26A4ED',
											color: 'white',
											fontWeight: 'bold',
											fontSize: 16
										}}>
										Order
									</HeaderCell>
									<EditableOrderCell dataKey="order" onDrag={handleDragRow} />
								</Column>
							</>
						)}
					</Table>
				</DndProvider>
				{/* </div> */}
			</div>
		</>
	);
};

export default AddOns;
