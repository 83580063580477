import { createSlice } from '@reduxjs/toolkit';
import { createUsers, deleteUsers, editUsers, getUsers, resetUsersPassword } from '../../reducers/PracticeInfoReducer';

const initialState = {
	loading: false,
	users: [],
	error: null,
	success: false
};

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {},
	extraReducers: builder => {

		// get users

		builder.addCase(getUsers.pending, state => {
			state.loading = true;
		});
		builder.addCase(getUsers.fulfilled, (state, action) => {
			state.loading = false;
			state.users = action.payload.data.list;
			state.error = false;
		});
		builder.addCase(getUsers.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// create users

		builder.addCase(createUsers.pending, state => {
			state.loading = true;
		});
		builder.addCase(createUsers.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(createUsers.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// edit users

		builder.addCase(editUsers.pending, state => {
			state.loading = true;
		});
		builder.addCase(editUsers.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(editUsers.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// delete users

		builder.addCase(deleteUsers.pending, state => {
			state.loading = true;
		});
		builder.addCase(deleteUsers.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(deleteUsers.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// delete users

		builder.addCase(resetUsersPassword.pending, state => {
			// state.loading = true;
			state.error = false;
		});
		builder.addCase(resetUsersPassword.fulfilled, (state, action) => {
			// state.loading = false;
			state.error = false;
		});
		builder.addCase(resetUsersPassword.rejected, (state, error) => {
			// state.loading = false;
			state.error = error.error.message;
		});
	}
});

export default usersSlice.reducer;
