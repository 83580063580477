import React, { useEffect, useState } from 'react';
import './MonthlyPaymentForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getPresentById } from '../../store/reducers/PresentationReducer';
import Loader from '../Loader/Loader';
import { PDFViewer } from '@react-pdf/renderer';
import MonthlyPaymentDownload from '../ContractDocument/MonthlyPaymentDownload';
import { monthlyPaymentDownloadStyles } from '../ContractDocument/styles';
import toast, { Toaster } from 'react-hot-toast';

const MonthlyPaymentForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { presentation, loading } = useSelector(state => state.presentation);
	const { id, date, treatmentId } = useParams();
	const [presentationInfo, setPresentationInfo] = useState(null);
	const [isBank, setIsBank] = useState(true);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		streetAddress: '',
		city: '',
		state: '',
		zipCode: '',
		phoneNumber: '',
		email: '',
		checking: false,
		saving: false,
		routingNumber: '',
		accountNumber: '',
		cardNumber: '',
		expirationDate: '',
		cvvCode: ''
	});

	const handleChange = e => {
		const { name, type, checked, value } = e.target;
		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value
		});
	};

	const validate = () => {
		let errors = {};

		if (!formData.firstName) {
			errors.firstName = 'First Name is required';
			toast.error('First Name is required');
		}
		if (!formData.lastName) {
			errors.lastName = 'Last Name is required';
			toast.error('Last Name is required');
		}
		if (!formData.streetAddress) {
			errors.streetAddress = 'Street Address is required';
			toast.error('Street Address is required');
		}
		if (!formData.city) {
			errors.city = 'City is required';
			toast.error('City is required');
		}
		if (!formData.state) {
			errors.state = 'State is required';
			toast.error('State is required');
		}
		if (!formData.zipCode) {
			errors.zipCode = 'Zip Code is required';
			toast.error('Zip Code is required');
		}
		if (!formData.phoneNumber) {
			errors.phoneNumber = 'Phone Number is required';
			toast.error('Phone Number is required');
		}
		if (!formData.email) {
			errors.email = 'Email is required';
			toast.error('Email is required');
		} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			errors.email = 'Email address is invalid';
			toast.error('Email address is invalid');
		}

		if (isBank) {
			if (!formData.routingNumber) {
				errors.routingNumber = 'Routing Number is required';
				toast.error('Routing Number is required');
			}
			if (!formData.accountNumber) {
				errors.accountNumber = 'Account Number is required';
				toast.error('Account Number is required');
			}
		} else {
			if (!formData.cardNumber) {
				errors.cardNumber = 'Card Number is required';
				toast.error('Card Number is required');
			}
			if (!formData.expirationDate) {
				errors.expirationDate = 'Expiration Date is required';
				toast.error('Expiration Date is required');
			}
			if (!formData.cvvCode) {
				errors.cvvCode = 'CVV Code is required';
				toast.error('CVV Code is required');
			}
		}

		if (Object.keys(errors).length === 0) {
			return true;
		} else {
			return false;
		}
	};

	const handleNext = () => {
		const result = validate();
		console.log(result);
		if (result) {
			localStorage.setItem('monthlyPaymentData', JSON.stringify(formData));
			navigate(`/monthly-payment-contract/${id}/${date}/${treatmentId}`);
		}
	};

	useEffect(() => {
		if (!presentation) {
			dispatch(getPresentById({ id, date, treatmentId }));
		}
	}, []);

	useEffect(() => {
		console.log(formData);;
	}, [formData]);

	useEffect(() => {
		setPresentationInfo(presentation);
	}, [presentation]);

	useEffect(() => {
		const savedData = localStorage.getItem('monthlyPaymentData');
		if (savedData) {
			setFormData(JSON.parse(savedData));
		}
	}, [presentation]);

	if (loading) {
		return (
			<div className="payment-wrapper">
				<div className="payment-block">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<>
			<Toaster position="top-right" />
			<div className="monthly-payment-form-wrapper">
				<img src="" alt="" className="ortho-bank" />
				<h4 className="monthly-payment-form-header">Monthly Payment Account Information</h4>
				<p className="monthly-payment-form-subheader">FMY Orthodontics, PC</p>
				<p className="monthly-payment-form-title">TYPE OF ACCOUNT</p>
				<div className="payment-form-buttons">
					<button
						className={`monthly-payment-form-button ${isBank ? 'active-button' : null}`}
						onClick={() => setIsBank(true)}>
						Bank Account
					</button>
					<button
						className={`monthly-payment-form-button ${isBank ? null : 'active-button'}`}
						onClick={() => setIsBank(false)}>
						Credit / Debit Card
					</button>
				</div>
				<form action="" className="monthly-payment-form">
					<div className="monthly-payment-name-section">
						<div className="monthly-payment-title-block">Name on the Account</div>
						<div className="monthly-payment-input-block-wrapper">
							<div className="monthly-payment-input-block-half">
								<p className="monthly-payment-input-title">First Name</p>
								<input
									type="text"
									className="monthly-payment-input"
									name="firstName"
									value={formData.firstName}
									onChange={handleChange}
								/>
							</div>
							<div className="monthly-payment-input-block-half">
								<p className="monthly-payment-input-title">Last Name</p>
								<input
									type="text"
									className="monthly-payment-input"
									name="lastName"
									value={formData.lastName}
									onChange={handleChange}
								/>
							</div>
						</div>
					</div>
					<div className="monthly-payment-name-section">
						<div className="monthly-payment-title-block">Billing Address</div>
						<div className="monthly-payment-input-block-full">
							<p className="monthly-payment-input-title">Street Address</p>
							<input
								type="text"
								className="monthly-payment-input"
								name="streetAddress"
								value={formData.streetAddress}
								onChange={handleChange}
							/>
						</div>
						<div className="monthly-payment-input-block-wrapper">
							<div className="monthly-payment-input-block-half">
								<p className="monthly-payment-input-title">City</p>
								<input
									type="text"
									className="monthly-payment-input"
									name="city"
									value={formData.city}
									onChange={handleChange}
								/>
							</div>
							<div className="monthly-payment-input-block-half">
								<p className="monthly-payment-input-title">State / Province</p>
								<input
									type="text"
									className="monthly-payment-input"
									name="state"
									value={formData.state}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="monthly-payment-input-block-full">
							<p className="monthly-payment-input-title">Postal / Zip Code</p>
							<input
								type="text"
								className="monthly-payment-input"
								name="zipCode"
								value={formData.zipCode}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="monthly-payment-name-section-wrapper">
						<div className="monthly-payment-name-section-half">
							<div className="monthly-payment-title-block">Phone Number Associated with Account</div>
							<div className="monthly-payment-input-block-wrapper">
								<div className="monthly-payment-input-block-full">
									<p className="monthly-payment-input-title">Please enter a valid phone number</p>
									<input
										type="text"
										className="monthly-payment-input"
										name="phoneNumber"
										value={formData.phoneNumber}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
						<div className="monthly-payment-name-section-half">
							<div className="monthly-payment-title-block">Email Address</div>
							<div className="monthly-payment-input-block-wrapper">
								<div className="monthly-payment-input-block-full">
									<p className="monthly-payment-input-title">example@example.com</p>
									<input
										type="email"
										className="monthly-payment-input"
										name="email"
										value={formData.email}
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="monthly-payment-name-section">
						<div className="monthly-payment-title-block">Account Information</div>
						<div className="monthly-payment-left">
							{isBank && (
								<div className="monthly-payment-checkbox-wrapper">
									<input type="checkbox" className="monthly-payment-checkbox" name='checking' checked={formData.checking || false} onChange={handleChange} />
									<p className="monthly-payment-checkbox-text">Checking</p>
									<input type="checkbox" className="monthly-payment-checkbox" name='saving' checked={formData.saving || false} onChange={handleChange} />
									<p className="monthly-payment-checkbox-text">Savings</p>
								</div>
							)}
							<div className="monthly-payment-number-input-wrapper">
								{isBank ? (
									<>
										<div className="monthly-payment-number-input-block">
											<p className="monthly-payment-number-input-title">ROUTING NUMBER</p>
											<input
												type="text"
												className="monthly-payment-number-input"
												name="routingNumber"
												value={formData.routingNumber}
												onChange={handleChange}
											/>
										</div>
										<div className="monthly-payment-number-input-block">
											<p className="monthly-payment-number-input-title">ACCOUNT NUMBER</p>
											<input
												type="text"
												className="monthly-payment-number-input"
												name="accountNumber"
												value={formData.accountNumber}
												onChange={handleChange}
											/>
										</div>
									</>
								) : (
									<>
										<div className="monthly-payment-number-input-block">
											<p className="monthly-payment-number-input-title">CARD NUMBER</p>
											<input
												type="text"
												className="monthly-payment-number-input"
												name="cardNumber"
												value={formData.cardNumber}
												onChange={handleChange}
											/>
										</div>
										<div className="monthly-payment-number-input-block">
											<p className="monthly-payment-number-input-title">EXPIRATION DATE</p>
											<input
												type="text"
												className="monthly-payment-number-input"
												name="expirationDate"
												value={formData.expirationDate}
												onChange={handleChange}
											/>
										</div>
										<div className="monthly-payment-number-input-block">
											<p className="monthly-payment-number-input-title">CVV CODE</p>
											<input
												type="text"
												className="monthly-payment-number-input"
												name="cvvCode"
												value={formData.cvvCode}
												onChange={handleChange}
											/>
										</div>
									</>
								)}
							</div>
						</div>
						<div className="monthly-payment-right">
							<div className="example"></div>
						</div>
					</div>
				</form>
				<div className="monthly-payment-button-wrapper">
					<button className="monthly-payment-next" onClick={handleNext}>
						Submit
					</button>
				</div>
			</div>
			{/* <PDFViewer showToolbar width={'100%'} height={2500}>
				<MonthlyPaymentDownload styles={monthlyPaymentDownloadStyles} />
			</PDFViewer> */}
		</>
	);
};

export default MonthlyPaymentForm;
