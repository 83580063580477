import React, { useState, useEffect } from 'react';
import './Profile.scss';

import { useNavigate } from 'react-router-dom';
import { users } from '../../data';
import Icon from '../../Icon';

import { useDispatch } from 'react-redux';
import { logout, resetPassword } from '../../store/reducers/AuthReduser';

const ProfileCode = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const [email, setEmail] = useState('');
  const [popup, setPopup] = useState(false);

  const navigate = useNavigate();

  const logoutFunc = () => {
    dispatch(logout());
    navigate('/login/admin');
  };

  const onSubmit = (e) => {
	e.preventDefault();
	const {email, code, password} = e.target
	const data = {
		email: email.value,
		code: code.value,
		password: password.value
	}
	dispatch(resetPassword(data)).then(() => {
		navigate('/profile')
	})
  };

  useEffect(() => {
    setEmail(user.email);
  }, []);

  return (
      <div className="profile-confirmation">
        <div className="profile-confirmation-header">
          <b className="profile-confirmation-header-details">
            Email & Password
          </b>
        </div>
        <form className="profile-confirmation-details" onSubmit={onSubmit}>
          <div className="profile-confirmation-details-title">Email *</div>
          <input
            className="profile-confirmation-details-input"
            type="email"
            defaultValue={email}
            // onChange={(e) => setEmail(e.target.value)}
            placeholder=""
			name='email'
          ></input>
		  <div className="profile-confirmation-details-title">VERIFICATION CODE *</div>
          <input
            className="profile-confirmation-details-input"
            type="password"
            placeholder=""
			name='code'
          ></input>
		  <div className="profile-confirmation-details-title">NEW PASSWORD *</div>
          <input
            className="profile-confirmation-details-input"
            type="password"
            placeholder=""
			name='password'
          ></input>
          <div className="profile-confirmation-details-button">
            <button
              className="profile-confirmation-details-button-content"
              type='submit'
            >
              Save
            </button>
          </div>
        </form>
      </div>
  );
};

export default ProfileCode;
