import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import {
	addOnsSlice,
	authSlice,
	discountsSlice,
	locationsSlice,
	patientSlice,
	practiceInfoSlice,
	treatmentTypesSlice,
	usersSlice,
	consultSlice,
	presentationSlice,
	paymentSlice
} from './slices';

export const store = configureStore({
	reducer: {
		auth: authSlice,
		practiceInfo: practiceInfoSlice,
		treatmentTypes: treatmentTypesSlice,
		addOns: addOnsSlice,
		discounts: discountsSlice,
		locations: locationsSlice,
		users: usersSlice,
		patient: patientSlice,
		consult: consultSlice,
		presentation: presentationSlice,
		payment: paymentSlice
	},
});

setupListeners(store.dispatch);

export default store;
