import React, { useState, useEffect } from 'react';
import './Profile.scss';

import { Outlet, useNavigate } from 'react-router-dom';
import { users } from '../../data';
import Icon from '../../Icon';

import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, logout } from '../../store/reducers/AuthReduser';
import Loader from '../../components/Loader/Loader';

const Profile = () => {
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const [email, setEmail] = useState('');
  const [userInfo, setUserInfo] = useState(null);
  const { currentUser, loading } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const logoutFunc = () => {
    dispatch(logout());
    navigate('/login/admin');
  };

  const sendConfirmationCode = () => {};

  function getInitials(str) {
    if (!str) return '';
    return str
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0)
      .map((word) => word[0].toUpperCase())
      .join('');
  }

  useEffect(() => {
    setEmail(user.email);
  }, []);

  useEffect(() => {
    dispatch(getUserInfo({ id: user.id }));
  }, []);

  useEffect(() => {
    if (currentUser) {
      setUserInfo(currentUser[0]);
    }
  }, [currentUser]);

  if (loading) {
    return (
      <div className="profile-container">
        <Loader />
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile-main">
        <div className="profile-main-avatar">
          {userInfo?.name ? getInitials(userInfo?.name) : ''}
        </div>
        <div className="profile-main-info">
          <h2 className="profile-main-info-title">My Profile</h2>
          <div className="profile-main-info-name">{userInfo?.name}</div>
        </div>
      </div>
      <Outlet />
      <div className="profile-logout" onClick={logoutFunc}>
        <div className="profile-logout-icon">
          <Icon name="logout" />
        </div>
        <div className="profile-logout-text">Logout</div>
        <div className="profile-logout-empty"></div>
      </div>
    </div>
  );
};

export default Profile;
