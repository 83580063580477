import { createSlice } from '@reduxjs/toolkit';
import { adminLogin, clientLogin, getUserInfo, logout, refresh, resetPassword, sendCode } from '../reducers/AuthReduser';

const initialState = {
	loading: false,
	userInfo: {},
	currentUser: null,
	error: null,
	success: false
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: builder => {

		//admin-login

		builder.addCase(adminLogin.pending, state => {
			state.loading = true;
		});
		builder.addCase(adminLogin.fulfilled, (state, action) => {
			state.loading = false;
			state.userInfo = action.payload.data.user;
			state.error = false;
		});
		builder.addCase(adminLogin.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		});

		//client-login

		builder.addCase(clientLogin.pending, state => {
			state.loading = true;
		});
		builder.addCase(clientLogin.fulfilled, (state, action) => {
			state.loading = false;
			state.userInfo = action.payload.data.user;
			state.error = false;
		});
		builder.addCase(clientLogin.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		});

		// refresh

		builder.addCase(refresh.pending, state => {
			state.loading = true;
		})
		builder.addCase(refresh.fulfilled, (state, action) => {
			state.loading = false;
			state.userInfo = action.payload.data?.user;
			state.error = false;
		})
		builder.addCase(refresh.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		})

		// send code

		builder.addCase(sendCode.pending, state => {
			state.loading = true;
		})
		builder.addCase(sendCode.fulfilled, (state, action) => {
			state.loading = false;
			// state.userInfo = action.payload.data?.user;
			state.error = false;
		})
		builder.addCase(sendCode.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		})

		// reset

		builder.addCase(resetPassword.pending, state => {
			state.loading = true;
		})
		builder.addCase(resetPassword.fulfilled, (state, action) => {
			state.loading = false;
			// state.userInfo = action.payload.data?.user;
			state.error = false;
		})
		builder.addCase(resetPassword.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		})
		

		// getUser

		builder.addCase(getUserInfo.pending, state => {
			state.loading = true;
		})
		builder.addCase(getUserInfo.fulfilled, (state, action) => {
			state.loading = false;
			state.currentUser = action.payload.data?.list;
			state.error = false;
		})
		builder.addCase(getUserInfo.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		})
		
		// logout

		builder.addCase(logout.fulfilled, (state, action) => {
			state.loading = false;
			state.userInfo = {};
			state.error = null;
			state.success = false;
		});
	}
});

export default authSlice.reducer;
