import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { getPresentById } from '../../store/reducers/PresentationReducer';
import { logout } from '../../store/reducers/AuthReduser';

const ThanksPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { presentation, loading } = useSelector(state => state.presentation);
	const [presentationInfo, setPresentationInfo] = useState(null);
	const { id, date, treatmentId } = useParams();
	const p = JSON.parse(localStorage.getItem('p'));

	const hanleLogout = () => {
		dispatch(logout()).then(() => {
			navigate(`login/${p}/${id}`);
		});
	};

	useEffect(() => {
		if (!presentation) {
			dispatch(getPresentById({ id, date, treatmentId }));
		}
	}, []);

	useEffect(() => {
		setPresentationInfo(presentation);
	}, [presentation]);

	if (loading) {
		return (
			<div className="payment-wrapper">
				<div className="payment-block">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<div className={styles.main}>
			<img
				src={presentationInfo?.organization.logo || ''}
				alt="avatar"
				width={150}
				height={150}
				style={{ borderRadius: '15px' }}
			/>
			<p>
				Thank you! We have sent you an email with a link to return to this page when you’re ready to continue.
			</p>
			<button className="payment-block-thinking-button" onClick={hanleLogout}>
				Logout
			</button>
		</div>
	);
};

export default ThanksPage;
