import React, { useEffect, useState } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import './Contract.scss';
import MonthlyPaymentDownload from '../ContractDocument/MonthlyPaymentDownload';
import { monthlyPaymentDownloadStyles } from '../ContractDocument/styles';
import MonthlyPaymentDocument from '../ContractDocument/MonthlyPaymentDocument';
import { getPresentById } from '../../store/reducers/PresentationReducer'

const MonthlyPaymentContract = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id, date, treatmentId } = useParams();
	const [savedData, setSavedData] = useState(null);
	const [presentationData, setPresentationData] = useState(null);
	const { presentation, loading } = useSelector(state => state.presentation);

	useEffect(() => {
		const data = JSON.parse(localStorage.getItem('monthlyPaymentData'));
		if (data) {
			setSavedData(data);
		}
	}, []);

	useEffect(() => {
		if (!presentation) {
			dispatch(getPresentById({ id, date, treatmentId }));
		}
	}, []);

	useEffect(() => {
		if (presentation) {
			setPresentationData(presentation)
		}
	}, [presentation]);

	if (!savedData || loading) {
		return (
			<div className="wrapper">
				<div className="contract-wrapper">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<div className="wrapper">
			<div className="contract-wrapper">
				<MonthlyPaymentDocument formData={savedData} presentationData={presentationData} />
				{
					// <MonthlyPaymentDownload
					// 	// isDownload={false}
					// 	styles={monthlyPaymentDownloadStyles}
					// />
				}
			</div>
			<div className="download-section">
				<PDFDownloadLink
					className="download-link"
					document={<MonthlyPaymentDownload styles={monthlyPaymentDownloadStyles} formData={savedData} presentationData={presentationData} />}
					fileName="contract.pdf">
					{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
				</PDFDownloadLink>
				{/* <button className="download-link next-link" onClick={handleNext}>
				Next
			</button> */}
			</div>
		</div>
	);
};

export default MonthlyPaymentContract;
