import React from 'react';
import './Presentation.scss'
import { PresentationInfo, PresentationSidebar } from '../../components';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader'

const Presentation = () => {
	return (
		<div className='presentation'>
			<PresentationSidebar />
			<Outlet />
		</div>
	);
};

export default Presentation;
