import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	currentPatient: {}
};

const patientSlice = createSlice({
	name: 'patient',
	initialState,
	reducers: {
		toggleEdit: (state, action) => {
			if(action.payload) {
				state.currentPatient = action.payload
			} else {
				state.currentPatient = []
			}
		}
	}
});

export const { toggleEdit } = patientSlice.actions;
export default patientSlice.reducer;
