import React, { useEffect, useState } from 'react';
import './Payment.scss';

import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux'
import { getPresentById } from '../../store/reducers/PresentationReducer'
import Loader from '../Loader/Loader'

const PaymentForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isBank, setIsBank] = useState(true);
	const { presentation, loading } = useSelector(state => state.presentation);
	const [ presentationInfo, setPresentationInfo ] = useState(null)
	const { id, date, treatmentId } = useParams();

	const handleCardPay = async () => {
		const stripe = await loadStripe('pk_test_51P6yUoBXWcSyXvSb7XyZSCvtgywH4SNZrHQlU5VMxxYoI39hnn7e0Ilp5afnztBq2RGtFxcqrf7Q6zuwwqnmS7Fi0058Z2atZ4')

		const body = {
			amount: presentationInfo?.calculations?.payments?.bpSelectedDownpayment_calc,
			currency: 'usd',
			id: +id,
			date: date,
			treatmentId: treatmentId,
		}

		const headers = {
			'Content-Type': 'application/json'
		}

		const response = await fetch(`https://api.fcform.shop/api/payment/${isBank ? 'bank-pay' : 'card-pay'}`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(body)
		})

		const session = await response.json()
		console.log(session);
		const result = stripe.redirectToCheckout({sessionId: session.id})

		if(result.error) {
			console.log(result.error)
		}
	}
	
	useEffect(() => {
		setPresentationInfo(presentation)
	}, [presentation]);

	useEffect(() => {
		if (!presentation) {
			dispatch(getPresentById({ id, date, treatmentId }));
		}
	}, []);

	if (loading) {
		return (
			<div className="payment-wrapper">
				<div className="payment-block">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<div className="payment-form-wrapper">
			<div className="payment-form-header">
				<img src={presentationInfo?.organization?.logo || ''} alt="avatar" width={150} height={150} />
				<p className="payment-form-header-text">Here is your down payment: ${presentationInfo?.calculations?.payments?.bpSelectedDownpayment_calc?.toFixed(2)}</p>
			</div>
			<div className="payment-form">
				<div className="payment-form-buttons">
					<button className={`payment-form-button ${isBank ? 'active-button': null}`} onClick={() => setIsBank(true)}>Bank Account</button>
					<button className={`payment-form-button ${isBank ? null : 'active-button'}`} onClick={() => setIsBank(false)}>Credit / Debit Card</button>
				</div>
				{isBank ? <button className='pay-button' onClick={handleCardPay}>Pay with Stripe (Bank Account)</button> : <button className='pay-button' onClick={handleCardPay}>Pay with Stripe (Card)</button>}
			</div>
		</div>
	);
};

export default PaymentForm;
