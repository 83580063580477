import React, { useEffect, useState } from 'react';
import './Header.scss';

import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { getConsults } from '../../store/reducers/ConsultReducer';

const Header = () => {
	const [consults, setConsults] = useState([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem('user'));
	const { consultations, loading, queryParams } = useSelector(state => state.consult);

	useEffect(() => {
		dispatch(getConsults({ queryParams }));
	}, []);

	useEffect(() => {
		setConsults([...consultations].reverse());
	}, [consultations]);

	return (
		<div className="main-header">
			{user?.role === 0 ? (
				<NavLink
					to={'/info/practice-information'}
					className={({ isActive, isPending }) =>
						isPending ? 'header-nav-item' : isActive ? 'header-active header-nav-item' : 'header-nav-item'
					}>
					<div className="header-nav-item-info">Settings</div>
				</NavLink>
			) : (
				<></>
			)}
			<NavLink
				to={`/consultations/${consults.length > 0 ? consults[0].id : ''}`}
				className={({ isActive, isPending }) =>
					isPending ? 'header-nav-item' : isActive ? 'header-active header-nav-item' : 'header-nav-item'
				}>
				<div className="header-nav-item-info">Consultations</div>
			</NavLink>
			<NavLink
				to={'/profile'}
				className={({ isActive, isPending }) =>
					isPending ? 'header-nav-item' : isActive ? 'header-active header-nav-item' : 'header-nav-item'
				}>
				<div className="header-nav-item-info">My Profile</div>
			</NavLink>
		</div>
	);
};

export default Header;
