import { Checkbox, Radio, SelectPicker, Table } from 'rsuite';
import Icon from '../Icon';
import 'rsuite/SelectPicker/styles/index.less';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { formatPhoneNumber } from './utils';
import { PhoneInput } from 'react-international-phone';

const ItemTypes = {
	ROW: 'row'
};

const style = {
	border: '1px dashed gray',
	padding: '0.3rem 1rem 0 1rem',
	cursor: 'move'
};

const { Cell } = Table;

export const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;

	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<input
					className="rs-input"
					value={rowData[dataKey]}
					style={{ width: '80%' }}
					onChange={event => {
						onChange && onChange(rowData.id, dataKey, event.target.value);
					}}
					disabled={!isActive}
				/>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey]}</span>
			)}
		</Cell>
	);
};

export const EditableCellFromOrder = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;

	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<input
					className="rs-input"
					value={rowData[dataKey]}
					style={{ width: '80%' }}
					onChange={event => {
						onChange && onChange(rowData.order, dataKey, event.target.value);
					}}
					disabled={!isActive}
				/>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey]}</span>
			)}
		</Cell>
	);
};

export const EditablePhoneCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;

	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<PhoneInput
					defaultCountry="us"
					value={rowData[dataKey]}
					onChange={event => {
						onChange && onChange(rowData.order, dataKey, event);
					}}
					hideDropdown
					style={{ width: '80%' }}
					disabled={!isActive}
				/>
			) : (
				<span className="table-content-edit-span">{formatPhoneNumber(rowData[dataKey])}</span>
			)}
		</Cell>
	);
};

export const EditableRoleCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';

	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				// <input
				// 	className="rs-input"
				// 	defaultValue={rowData[dataKey]}
				// 	style={{ width: '80%' }}
				// 	onChange={event => {
				// 		onChange && onChange(rowData.id, dataKey, event.target.value);
				// 	}}
				// />
				<SelectPicker
					className="rs-select"
					style={{ width: '80%' }}
					searchable={false}
					value={rowData[dataKey]}
					onChange={value => {
						onChange && onChange(rowData.id, dataKey, value);
					}}
					data={[
						{ value: 0, label: 'Admin' },
						{ value: 1, label: 'Staff' }
					]}
				/>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey] === 0 ? 'Admin' : 'Staff'}</span>
			)}
		</Cell>
	);
};

export const EditableOrderCell = ({ rowData, onDrag, ...props }) => {
	const ref = React.useRef(null);

	const [{ canDrop, isOver }, drop] = useDrop({
		accept: ItemTypes.ROW,
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		}),
		drop(item, monitor) {
			onDrag && onDrag(item.id, rowData.id);
		}
	});

	const [{ isDragging }, drag] = useDrag({
		item: { id: rowData.id },
		type: ItemTypes.ROW,
		collect: monitor => ({
			isDragging: monitor.isDragging()
		})
	});
	const isActive = canDrop && isOver;

	drag(drop(ref));

	const styles = {
		...style,
		opacity: isDragging ? 0.5 : 1,
		background: isActive ? '#ddd' : null
	};

	return (
		<Cell {...props} style={{ padding: 0 }}>
			<div ref={ref} style={styles}>
				<Icon name="order" styles={{ width: '24' }} />
			</div>
		</Cell>
	);
};

export const EditableResetPasswordCell = ({ rowData, dataKey, onClick, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;
	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			<button
				className="reset-user-password"
				style={{ width: '50%' }}
				onClick={() => onClick(rowData[dataKey])}
				disabled={!isActive}>
				Reset Password
			</button>
		</Cell>
	);
};

export const EditableFeesCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;
	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<input
					className="rs-input"
					value={rowData[dataKey]}
					style={{ width: '30%' }}
					onChange={event => {
						onChange && onChange(rowData.id, dataKey, event.target.value);
					}}
					disabled={!isActive}
				/>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey]}.00</span>
			)}
		</Cell>
	);
};

export const EditableFeeCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;
	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<input
					className="rs-input"
					value={rowData[dataKey]}
					style={{ width: '50%' }}
					onChange={event => {
						onChange && onChange(rowData.id, dataKey, event.target.value);
					}}
					disabled={!isActive}
				/>
			) : (
				<span className="table-content-edit-span">${rowData[dataKey].toFixed(2)}</span>
			)}
		</Cell>
	);
};

export const EditablePercentageCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;
	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<>
					<input
						className="rs-input"
						value={rowData[dataKey]}
						style={{ width: '10%' }}
						onChange={event => {
							onChange && onChange(rowData.id, dataKey, event.target.value);
						}}
						disabled={!isActive}
					/>
					%
				</>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey]}%</span>
			)}
		</Cell>
	);
};

export const EditableActiveCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';

	const handleCheckboxChange = () => {
		const newValue = !rowData[dataKey];
		onChange && onChange(rowData.id, dataKey, newValue);
	};

	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<>
					<Checkbox inline onChange={handleCheckboxChange} checked={rowData[dataKey]} />
				</>
			) : rowData.isActive === true ? (
				<Icon name="active" styles={{ width: 20, fill: '#26A4ED' }} />
			) : (
				<Icon name="inactive" styles={{ width: 20, fill: 'black' }} />
			)}
		</Cell>
	);
};

export const EditableVisibleCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';

	const handleCheckboxChange = () => {
		const newValue = !rowData[dataKey];
		onChange && onChange(rowData.id, dataKey, newValue);
	};

	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{rowData.isActive === true ? (
				<div className="visible-block" onClick={handleCheckboxChange}>
					<Icon name="visible" />
				</div>
			) : (
				<div className="visible-block" onClick={handleCheckboxChange}>
					<Icon name="invisible" />
				</div>
			)}
		</Cell>
	);
};

export const EditableNumberCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;
	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<input
					className="rs-input"
					value={rowData[dataKey]}
					style={{ width: '50%' }}
					onChange={event => {
						onChange && onChange(rowData.id, dataKey, event.target.value);
					}}
					disabled={!isActive}
				/>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey]}</span>
			)}
		</Cell>
	);
};

export const EditableRangeCell = ({ rowData, dataKey, onChange, ...props }) => {
	const isEditMode = rowData.status === 'EDIT';
	const isActive = rowData.isActive;

	const handleInputChange = (index, value) => {
		const newArray = [...rowData[dataKey]];
		newArray[index] = value;
		onChange && onChange(rowData.id, dataKey, newArray);
	};

	return (
		<Cell {...props} className={isEditMode ? 'table-content-editing' : ''}>
			{isEditMode ? (
				<>
					<input
						className="rs-input"
						value={rowData[dataKey][0]}
						style={{ width: '30%' }}
						onChange={event => {
							handleInputChange(0, event.target.value);
						}}
						disabled={!isActive}
					/>
					to
					<input
						className="rs-input"
						value={rowData[dataKey][1]}
						style={{ width: '30%' }}
						onChange={event => {
							handleInputChange(1, event.target.value);
						}}
						disabled={!isActive}
					/>
				</>
			) : (
				<span className="table-content-edit-span">{rowData[dataKey][0] + ' to ' + rowData[dataKey][1]}</span>
			)}
		</Cell>
	);
};

export const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
	<Cell {...props} style={{ padding: 0 }}>
		<div>
			<Checkbox
				value={rowData[dataKey]}
				inline
				verticalalign="middle"
				onChange={onChange}
				checked={checkedKeys.some(item => item === rowData[dataKey])}
			/>
		</div>
	</Cell>
);

export const RadioCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
	<Cell {...props} style={{ padding: 0 }}>
		<div className="radio-cell">
			<div className="radio-wrapper" onClick={() => onChange(rowData[dataKey])}>
				<Radio
					value={rowData[dataKey]}
					inline
					verticalalign="middle"
					// onChange={onChange}
					checked={checkedKeys === rowData[dataKey]}
				/>
			</div>
		</div>
	</Cell>
);
