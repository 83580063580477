import React, { useEffect, useState } from 'react';
import './Users.scss';

import { Checkbox, Table } from 'rsuite';
import Icon from '../../Icon';
import {
	CheckCell,
	EditableActiveCell,
	EditableCell,
	EditableOrderCell,
	EditableResetPasswordCell,
	EditableRoleCell,
	RadioCell
} from '../../utils/cells';
import toast, { Toaster } from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import {
	createUsers,
	deleteUsers,
	editUsers,
	getUsers,
	resetUsersPassword
} from '../../store/reducers/PracticeInfoReducer';
import Loader from '../Loader/Loader';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const { Column, HeaderCell } = Table;

const Users = () => {
	const dispatch = useDispatch();
	const { loading, treatmentTypes, users, error } = useSelector(state => state.users);
	const [checkedKeys, setCheckedKeys] = useState(null);
	const [isEdit, setIsEdit] = useState(false);
	const [usersArray, setUsersArray] = useState(users || []);

	const handleCheck = value => {
		if (checkedKeys === value) {
			setCheckedKeys(null);
		} else {
			setCheckedKeys(value);
		}
	};

	const handleActiveClick = (id, key, value) => {
		const nextData = [...usersArray];
		nextData.find(item => item.id === id)[key] = value;
		setUsersArray(nextData);
	};

	const onEditClick = () => {
		setIsEdit(true);
		const nextData = usersArray.map(item => ({ ...item, status: isEdit ? null : 'EDIT' }));
		setUsersArray(nextData);
	};

	const onSaveClick = () => {
		const handleSave = () => {
			setIsEdit(false);
			const sortedData = [...usersArray]
				.reverse()
				.map((item, index) => ({ ...item, order: index + 1 }))
				.sort((a, b) => b.order - a.order);
			console.log('sortedData', sortedData);

			const createdData = sortedData.filter(item => item.created);
			const editedData = sortedData.filter(item => !item?.created);

			const nextCreateData = createdData.map(item => {
				const { status, created, role, ...newItem } = item;
				return { ...newItem, role: parseInt(role) };
			});

			const nextEditData = editedData.map(item => {
				const { status, created, role, ...newItem } = item;
				return { ...newItem, role: parseInt(role) };
			});

			dispatch(editUsers(nextEditData));
			dispatch(createUsers(nextCreateData));
			setUsersArray([...nextEditData, ...nextCreateData]);
		};

		const handleDiscard = () => {
			const sortedUsers = [...users]?.sort((a, b) => b.order - a.order);
			setUsersArray(sortedUsers);
			setIsEdit(false);
			toast.dismiss();
		};

		toast(t => (
			<span>
				<b>Do you want to save your changes?</b>
				<div className="save-toast">
					<button
						onClick={() => {
							toast.dismiss(t.id);
							handleSave();
						}}
						className="save-toast-yes">
						Yes
					</button>
					<button onClick={() => handleDiscard()} className="save-toast-no">
						No
					</button>
				</div>
			</span>
		));
	};

	const onDeleteClick = () => {
		if (checkedKeys) {
			dispatch(deleteUsers(checkedKeys));
			setCheckedKeys(null);
		}
	};

	const onAddClick = () => {
		const maxOrder = Math.max(...usersArray.map(item => item.order), 0);
		const newUser = {
			name: '',
			email: '',
			role: 0,
			languageCulture: 'en-US',
			status: 'EDIT',
			created: true,
			isActive: true,
			order: maxOrder + 1
		};

		const nextData = usersArray.map(item => ({ ...item, status: 'EDIT' }));
		const updatedAddOnsArray = [newUser, ...nextData];
		setUsersArray(updatedAddOnsArray);
		if (!isEdit) {
			setIsEdit(true);
		}
	};

	const handleChange = (id, key, value) => {
		const nextData = [...usersArray];
		nextData.find(item => item.id === id)[key] = value;
		setUsersArray(nextData);
	};

	const handleDragRow = (sourceId, targetId) => {
		const draggedItem = usersArray.find(item => item.id === sourceId);
		const draggedIndex = usersArray.findIndex(item => item.id === sourceId);
		const targetIndex = usersArray.findIndex(item => item.id === targetId);

		if (draggedIndex === -1 || targetIndex === -1) {
			return;
		}

		const updatedArray = [...usersArray];

		updatedArray.splice(draggedIndex, 1);

		updatedArray.splice(targetIndex, 0, draggedItem);

		setUsersArray(updatedArray);
	};

	const handleResetPassword = email => {
		console.log(email);

		const handleSave = () => {
			toast.promise(dispatch(resetUsersPassword(email)), {
				loading: 'Loading',
				success: 'Success',
				error: 'Error'
			});
		};

		toast(t => (
			<span>
				<b>Do you want to send a new password to the email?</b>
				<div className="save-toast">
					<button
						onClick={() => {
							toast.dismiss(t.id);
							handleSave();
						}}
						className="save-toast-yes">
						Yes
					</button>
					<button onClick={() => toast.dismiss(t.id)} className="save-toast-no">
						No
					</button>
				</div>
			</span>
		));
	};

	useEffect(() => {
		const sortedUsers = [...users]?.sort((a, b) => b.order - a.order);
		setUsersArray(sortedUsers);
	}, [users]);

	useEffect(() => {
		dispatch(getUsers());
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Toaster position="top-right" />
			<div className="info-container">
				<div className="info-header">
					<div className="info-header-right">
						<div className="info-header-right-title">Users</div>
					</div>
					<div className="info-header-left">
						{isEdit ? (
							<>
								<div className="info-header-left-add" onClick={onAddClick}>
									<Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
								</div>
								<div className="info-header-left-edit" onClick={onSaveClick}>
									<Icon name="save" />
								</div>
							</>
						) : (
							<>
								<div className="info-header-left-add" onClick={onAddClick}>
									<Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
								</div>
								<div className="info-header-left-edit" onClick={onEditClick}>
									<Icon name="edit" />
								</div>
								<div className="info-header-left-delete" onClick={onDeleteClick}>
									<Icon name="delete" />
								</div>
							</>
						)}
					</div>
				</div>
				<DndProvider backend={HTML5Backend}>
					<Table data={usersArray} autoHeight>
						<Column width={50} align="center">
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								<div>
									{/* <Checkbox
										inline
										checked={checked}
										verticalalign="middle"
										indeterminate={indeterminate}
										onChange={handleCheckAll}
									/> */}
								</div>
							</HeaderCell>
							<RadioCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />
						</Column>
						<Column flexGrow={1} align="start" verticalalign="middle" height={50}>
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								Name
							</HeaderCell>
							<EditableCell dataKey="name" onChange={handleChange} />
						</Column>
						<Column flexGrow={2} align="center" verticalalign="middle">
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								Email
							</HeaderCell>
							<EditableCell dataKey="email" onChange={handleChange} />
						</Column>
						<Column flexGrow={1} align="center" verticalalign="middle">
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								Role
							</HeaderCell>
							<EditableRoleCell dataKey="role" onChange={handleChange} />
						</Column>
						<Column flexGrow={1} align="center" verticalalign="middle">
							<HeaderCell
								style={{
									backgroundColor: '#26A4ED',
									color: 'white',
									fontWeight: 'bold',
									fontSize: 16
								}}>
								Active
							</HeaderCell>
							<EditableActiveCell dataKey="isActive" onChange={handleActiveClick} />
						</Column>
						{isEdit && (
							<Column flexGrow={1} align="center" verticalalign="middle">
								<HeaderCell
									style={{
										backgroundColor: '#26A4ED',
										color: 'white',
										fontWeight: 'bold',
										fontSize: 16
									}}>
									Order
								</HeaderCell>
								<EditableOrderCell dataKey="order" onDrag={handleDragRow} />
							</Column>
						)}
						{isEdit && (
							<Column flexGrow={2} align="center" verticalalign="middle">
								<HeaderCell
									style={{
										backgroundColor: '#26A4ED',
										color: 'white',
										fontWeight: 'bold',
										fontSize: 16
									}}>
									Reset Password
								</HeaderCell>
								<EditableResetPasswordCell dataKey="email" onClick={handleResetPassword} />
							</Column>
						)}
					</Table>
				</DndProvider>
			</div>
		</>
	);
};

export default Users;
