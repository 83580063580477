import { createSlice } from '@reduxjs/toolkit';
import { getTreatmentTypes, getTreatmentTypesAddOns, createTreatmentTypes, editTreatmentTypes, deleteTreatmentTypes, postTreatmentTypesAddOns, deleteTreatmentTypesAddOns, getTreatmentTypeAddOns } from '../../reducers/PracticeInfoReducer';

const initialState = {
	loading: false,
	treatmentTypes: [],
	treatmentTypeAddOns: [],
	error: null,
	success: false
};

const treatmentTypesSlice = createSlice({
	name: 'treatmentTypes',
	initialState,
	reducers: {},
	extraReducers: builder => {

		// get treatment types

		builder.addCase(getTreatmentTypes.pending, state => {
			state.loading = true;
		});
		builder.addCase(getTreatmentTypes.fulfilled, (state, action) => {
			state.loading = false;
			state.treatmentTypes = action.payload.data.list;
			state.error = false;
		});
		builder.addCase(getTreatmentTypes.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//get treatment types add-ons

		builder.addCase(getTreatmentTypesAddOns.pending, state => {
			state.loading = true;
		});
		builder.addCase(getTreatmentTypesAddOns.fulfilled, (state, action) => {
			state.loading = false;
			state.treatmentTypeAddOns = action.payload.data.list;
			state.error = false;
		});
		builder.addCase(getTreatmentTypesAddOns.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//post treatment types add-ons

		builder.addCase(postTreatmentTypesAddOns.pending, state => {
			state.loading = true;
		});
		builder.addCase(postTreatmentTypesAddOns.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(postTreatmentTypesAddOns.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//delete treatment types add-ons

		builder.addCase(deleteTreatmentTypesAddOns.pending, state => {
			state.loading = true;
		});
		builder.addCase(deleteTreatmentTypesAddOns.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(deleteTreatmentTypesAddOns.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//create reatment types

		builder.addCase(createTreatmentTypes.pending, state => {
			state.loading = true;
		});
		builder.addCase(createTreatmentTypes.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(createTreatmentTypes.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//edit treatment types

		builder.addCase(editTreatmentTypes.pending, state => {
			state.loading = true;
		});
		builder.addCase(editTreatmentTypes.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(editTreatmentTypes.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//delete treatment types 

		builder.addCase(deleteTreatmentTypes.pending, state => {
			state.loading = true;
		});
		builder.addCase(deleteTreatmentTypes.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(deleteTreatmentTypes.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});
	}
});

export default treatmentTypesSlice.reducer;
