import { createSlice } from '@reduxjs/toolkit';
import { createAddOns, deleteAddOns, editAddOns, getAddOns } from '../../reducers/PracticeInfoReducer';

const initialState = {
	loading: false,
	addOns: [],
	error: null,
	success: false
};

const addOnsSlice = createSlice({
	name: 'addOns',
	initialState,
	reducers: {},
	extraReducers: builder => {

		// get addOns

		builder.addCase(getAddOns.pending, state => {
			state.loading = true;
		});
		builder.addCase(getAddOns.fulfilled, (state, action) => {
			state.loading = false;
			state.addOns = action.payload.data.list;
			state.error = false;
		});
		builder.addCase(getAddOns.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// create addOns

		builder.addCase(createAddOns.pending, state => {
			state.loading = true;
		});
		builder.addCase(createAddOns.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(createAddOns.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// edit addOns

		builder.addCase(editAddOns.pending, state => {
			state.loading = true;
		});
		builder.addCase(editAddOns.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(editAddOns.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//delete addOns

		builder.addCase(deleteAddOns.pending, state => {
			state.loading = true;
		});
		builder.addCase(deleteAddOns.fulfilled, (state, action) => {
			state.loading = false;
			state.error = false;
		});
		builder.addCase(deleteAddOns.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});
	}
});

export default addOnsSlice.reducer;
