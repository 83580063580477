import React, { useEffect } from 'react';
import './Main.scss';

import { Outlet, useNavigate } from 'react-router-dom';
import { Header } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/reducers/AuthReduser';

const Main = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem('user'));
	const { error: consltError } = useSelector(state => state.consult);
	const { error: discountsError } = useSelector(state => state.discounts);
	const { error: locationsError } = useSelector(state => state.locations);
	const { error: treatmentTypesError } = useSelector(state => state.treatmentTypes);
	const { error: patientError } = useSelector(state => state.patient);
	const { error: practiceInfoError } = useSelector(state => state.practiceInfo);
	const { error: addOnsError } = useSelector(state => state.addOns);
	const { error: usersError } = useSelector(state => state.users);
	const { error: presentationError } = useSelector(state => state.presentation);

	useEffect(() => {
		if (!user) {
			navigate('/login/admin');
		}
	}, [user, navigate]);

	useEffect(() => {
		if (
			consltError === '401' ||
			discountsError === '401' ||
			locationsError === '401' ||
			treatmentTypesError === '401' ||
			patientError === '401' ||
			practiceInfoError === '401' ||
			addOnsError === '401' ||
			usersError === '401' ||
			presentationError === '401'
		) {
			dispatch(logout());
		}
	}, [
		consltError,
		discountsError,
		locationsError,
		treatmentTypesError,
		patientError,
		practiceInfoError,
		addOnsError,
		usersError,
		presentationError
	]);

	return (
		<div className="main-container">
			<Header />
			<div className="main-content">
				<Outlet />
			</div>
		</div>
	);
};

export default Main;
