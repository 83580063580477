import { createSlice } from '@reduxjs/toolkit';
import { getPayment, getSignedMonthlyContract, postPaymentSuccess, signMonthlyContract } from '../reducers/PaymentReducer'

const initialState = {
	loading: false,
	payment: null,
	monthlyContract: null,
	error: null,
	success: false
};

const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {},
	extraReducers: builder => {
		//get Payment

		builder.addCase(getPayment.pending, state => {
			state.loading = true;
		});
		builder.addCase(getPayment.fulfilled, (state, action) => {
			state.loading = false;
			state.payment = action.payload.data;
			state.error = false;
		});
		builder.addCase(getPayment.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		});

		//post payment success

		builder.addCase(postPaymentSuccess.pending, state => {
			state.loading = true;
		});
		builder.addCase(postPaymentSuccess.fulfilled, (state, action) => {
			state.loading = false;
			state.payment = action.payload.data;
			state.error = false;
		});
		builder.addCase(postPaymentSuccess.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		});

		//post monthly contract

		builder.addCase(signMonthlyContract.pending, state => {
			state.loading = true;
		});
		builder.addCase(signMonthlyContract.fulfilled, (state, action) => {
			state.loading = false;
			state.payment = action.payload.data;
			state.error = false;
		});
		builder.addCase(signMonthlyContract.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		});

		//get signed monthly contract

		builder.addCase(getSignedMonthlyContract.pending, state => {
			state.loading = true;
		});
		builder.addCase(getSignedMonthlyContract.fulfilled, (state, action) => {
			state.loading = false;
			state.monthlyContract = action.payload.data;
			state.error = false;
		});
		builder.addCase(getSignedMonthlyContract.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error;
		});
	}
});

export default paymentSlice.reducer;
