import React, { useEffect, useState } from 'react';
import './Contract.scss';
import { BlobProvider, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
import { getPresentById, getSignedContract } from '../../store/reducers/PresentationReducer';
import { useParams } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { downloadStyles, styles } from '../ContractDocument/styles';
import ContractDocument from '../ContractDocument/ContractDocument';
import { useNavigate } from 'react-router-dom';
import ContractDownload from '../ContractDocument/ContractDownload';
import { saveAs } from 'file-saver';
import { stringToPdf } from '../../utils/utils';

const Contract = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { presentation, contract, loading } = useSelector(state => state.presentation);
	const [presentationInfo, setPresentationInfo] = useState(null);
	const [isNoCharge, setIsNoCharge] = useState(false);
	const [pdf, setPdf] = useState(null);
	const { id, date, treatmentId } = useParams();

	const handleNext = () => {
		navigate(`/payment/${id}/${date}/${treatmentId}`);
	};

	const handleDownload = () => {
		saveAs(pdf, 'document.pdf');
	};

	useEffect(() => {
		setPresentationInfo(presentation);
	}, [presentation]);

	useEffect(() => {
		const hasNoChargeAddon = presentationInfo?.treatments?.addOns?.some(addOn => addOn.name === "No Charge");
		setIsNoCharge(hasNoChargeAddon);
	}, [presentationInfo]);

	useEffect(() => {
		dispatch(getPresentById({ id, date, treatmentId }));
	}, []);

	useEffect(() => {
		if (presentation && presentation?.status > 2 && presentation?.contractUrl) {
			dispatch(getSignedContract({ id }));
		}
	}, [presentation]);

	useEffect(() => {
		console.log(contract);
		if (contract) {
			// const byteCharacters = atob(contract.pdf);
			// const byteNumbers = new Array(byteCharacters.length);
			// for (let i = 0; i < byteCharacters.length; i++) {
			// 	byteNumbers[i] = byteCharacters.charCodeAt(i);
			// }
			// const byteArray = new Uint8Array(byteNumbers);
			// const blob = new Blob([byteArray], { type: 'application/pdf' });
			const blob = stringToPdf(contract);
			setPdf(blob);
			// const blob = new Blob([pdf], { type: 'application/pdf' })
			// console.log(pdf);
			console.log('blob', blob);
			// saveAs(blob, 'document.pdf');
		}
	}, [contract]);

	if (loading || !presentationInfo) {
		return (
			<div className="wrapper">
				<div className="contract-wrapper">
					<Loader />
				</div>
			</div>
		);
	}

	return (
		<div className="wrapper">
			<div className="contract-wrapper">
				{pdf ? <ContractDocument isDownload={false} styles={styles}></ContractDocument> : presentationInfo ? (
					<ContractDocument isDownload={false} styles={styles}></ContractDocument>
					// <ContractDownload
					// 	isDownload={false}
					// 	styles={downloadStyles}
					// 	presentationInfo={presentationInfo}
					// 	signature={null}
					// />
				) : <></>}
			</div>
			<div className="download-section">
				{pdf ? (
					<button className="download-link" onClick={handleDownload}>
						Download PDF
					</button>
				) : (
					<PDFDownloadLink
						className="download-link"
						document={
							<ContractDownload
								isDownload={true}
								styles={downloadStyles}
								presentationInfo={presentationInfo}
								signature={null}
							/>
						}
						fileName="contract.pdf">
						{({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
					</PDFDownloadLink>
				)}
			</div>
		</div>
	);
};

export default Contract;
