import { createSlice } from '@reduxjs/toolkit';
import { accept, getContract, getPresent, getPresentById, getSignedContract, patchTreatments, postEnabled, share, signContract, stillThinking } from '../../reducers/PresentationReducer'

const initialState = {
	loading: false,
	presentationTreatments: [],
	presentation: null,
	contract: null,
	error: null,
	success: false,
};

const presentationSlice = createSlice({
	name: 'presentation',
	initialState,
	reducers: {},
	extraReducers: builder => {
		//get presentation

		builder.addCase(getPresent.pending, state => {
			state.loading = true;
		});
		builder.addCase(getPresent.fulfilled, (state, action) => {
			state.loading = false;
			state.presentationTreatments = action.payload.data;
			state.error = false;
		});
		builder.addCase(getPresent.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		// get presentation details by id

		builder.addCase(getPresentById.pending, state => {
			state.loading = true;
		});
		builder.addCase(getPresentById.fulfilled, (state, action) => {
			state.loading = false;
			state.presentation = action.payload.data;
			state.error = false;
		});
		builder.addCase(getPresentById.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//patch treatments

		builder.addCase(patchTreatments.pending, state => {
			state.loading = true;
		});
		builder.addCase(patchTreatments.fulfilled, (state, action) => {
			state.loading = false;
			// state.presentation = action.payload.data;
			state.error = false;
		});
		builder.addCase(patchTreatments.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//get contract

		builder.addCase(getContract.pending, state => {
			state.loading = true;
		});
		builder.addCase(getContract.fulfilled, (state, action) => {
			state.loading = false;
			state.contract = action.payload.data;
			state.error = false;
		});
		builder.addCase(getContract.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//share

		builder.addCase(share.pending, state => {
			state.loading = true;
		});
		builder.addCase(share.fulfilled, (state, action) => {
			state.loading = false;
			// state.contract = action.payload.data;
			state.error = false;
		});
		builder.addCase(share.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//share

		builder.addCase(stillThinking.pending, state => {
			state.loading = true;
		});
		builder.addCase(stillThinking.fulfilled, (state, action) => {
			state.loading = false;
			// state.contract = action.payload.data;
			state.error = false;
		});
		builder.addCase(stillThinking.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//share

		builder.addCase(postEnabled.pending, state => {
			state.loading = true;
		});
		builder.addCase(postEnabled.fulfilled, (state, action) => {
			state.loading = false;
			// state.contract = action.payload.data;
			state.error = false;
		});
		builder.addCase(postEnabled.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//accept

		builder.addCase(accept.pending, state => {
			state.loading = true;
		});
		builder.addCase(accept.fulfilled, (state, action) => {
			state.loading = false;
			// state.contract = action.payload.data;
			state.error = false;
		});
		builder.addCase(accept.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//sign contract

		builder.addCase(signContract.pending, state => {
			state.loading = true;
		});
		builder.addCase(signContract.fulfilled, (state, action) => {
			state.loading = false;
			// state.contract = action.payload.data;
			state.error = false;
		});
		builder.addCase(signContract.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

		//get signed contract

		builder.addCase(getSignedContract.pending, state => {
			state.loading = true;
		});
		builder.addCase(getSignedContract.fulfilled, (state, action) => {
			state.loading = false;
			state.contract = action.payload.data;
			state.error = false;
		});
		builder.addCase(getSignedContract.rejected, (state, error) => {
			state.loading = false;
			state.error = error.error.message;
		});

	}
});

export default presentationSlice.reducer;