import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { refresh } from './AuthReduser';

export const getPracticeInfo = createAsyncThunk('practiceInfo', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.get(`https://api.fcform.shop/api/practice-info?id=${data.practiceId}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const editPracticeInfo = createAsyncThunk('practiceInfo/edit', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}
		console.log(data);

		const token = JSON.parse(localStorage.getItem('userToken'));

		if (data.file) {
			const formData = new FormData();
			formData.append('file', data.file, data.file.name);
			for (let key in data.data) {
				if (Array.isArray(data.data[key])) {
					data.data[key].forEach((value, index) => {
						formData.append(`${key}[${index}]`, value);
					});
				} else {
					formData.append(key, data.data[key]);
				}
			}

			const res = await axios.put('https://api.fcform.shop/api/practice-info', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
				}
			});

			const resData = {
				data: res.data
			};

			return resData;
		}

		const res = await axios.put(
			'https://api.fcform.shop/api/practice-info',
			{
				...data.data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getTreatmentTypes = createAsyncThunk('treatmentTypes', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.get(`https://api.fcform.shop/api/treatment-types/?size=1000&sortDir=desc&sortBy=id`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getTreatmentTypesAddOns = createAsyncThunk('treatmentTypes/add-ons', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const treatmentTypeIds = data?.ids?.join(',');

		const res = await axios.get(`https://api.fcform.shop/api/treatment-types/add-ons?treatmentTypeIds=${treatmentTypeIds}`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const postTreatmentTypesAddOns = createAsyncThunk('treatmentTypes/post-add-ons', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(`https://api.fcform.shop/api/treatment-types/add-ons`, {
			treatmentTypeId: data.treatmentTypeId,
			addOnsIds: data.addOnsIds
		}, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const deleteTreatmentTypesAddOns = createAsyncThunk('treatmentTypes/delete-add-ons', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(`https://api.fcform.shop/api/treatment-types/delete-add-ons`, {
			treatmentTypeId: data.treatmentTypeId,
			addOnId: data.addOnId
		}, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const createTreatmentTypes = createAsyncThunk('treatmentTypes/create', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/treatment-types`,
			{ itemsToCreate: data },
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getTreatmentTypes());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const editTreatmentTypes = createAsyncThunk('treatmentTypes/edit', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/treatment-types`,
			{ itemsToUpdate: data },
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getTreatmentTypes());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const deleteTreatmentTypes = createAsyncThunk('treatmentTypes/delete', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(
			`https://api.fcform.shop/api/treatment-types/delete`,
			{ itemsIds: [data] },
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getTreatmentTypes());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getAddOns = createAsyncThunk('add-ons', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.get(`https://api.fcform.shop/api/add-ons/?size=1000&sortDir=desc&sortBy=id`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const createAddOns = createAsyncThunk('add-ons/create', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/add-ons`,
			{
				itemsToCreate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getAddOns());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const editAddOns = createAsyncThunk('add-ons/edit', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/add-ons`,
			{
				itemsToUpdate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getAddOns());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const deleteAddOns = createAsyncThunk('add-ons/delete', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(
			`https://api.fcform.shop/api/add-ons/delete`,
			{
				itemsIds: [data]
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getAddOns());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getDiscounts = createAsyncThunk('discounts', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.get(`https://api.fcform.shop/api/discounts/?size=1000&sortDir=desc&sortBy=id`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const createDiscounts = createAsyncThunk('discounts/create', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/discounts`,
			{
				itemsToCreate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getDiscounts());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const editDiscounts = createAsyncThunk('discounts/edit', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/discounts`,
			{
				itemsToUpdate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getDiscounts());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const deleteDisconts = createAsyncThunk('discounts/delete', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(
			`https://api.fcform.shop/api/discounts/delete`,
			{
				itemsIds: [data]
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getDiscounts());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getLocations = createAsyncThunk('locations', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.get(`https://api.fcform.shop/api/locations/?size=1000&sortDir=desc&sortBy=id`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const editLocations = createAsyncThunk('locations/edit', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/locations`,
			{
				itemsToUpdate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getLocations());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const createLocations = createAsyncThunk('locations/create', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/locations`,
			{
				itemsToCreate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getLocations());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const deleteLocations = createAsyncThunk('locations/delete', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(
			`https://api.fcform.shop/api/locations/delete`,
			{
				itemsIds: [data]
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getLocations());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const getUsers = createAsyncThunk('users', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.get(`https://api.fcform.shop/api/users/?size=1000&sortDir=desc&sortBy=id`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const createUsers = createAsyncThunk('users/create', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/users`,
			{
				itemsToCreate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getUsers());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const editUsers = createAsyncThunk('users/edit', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.patch(
			`https://api.fcform.shop/api/users`,
			{
				itemsToUpdate: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getUsers());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const deleteUsers = createAsyncThunk('users/delete', async (data, thunkAPI) => {
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(
			`https://api.fcform.shop/api/users/delete`,
			{
				itemsIds: [data]
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		await thunkAPI.dispatch(getUsers());

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});

export const resetUsersPassword = createAsyncThunk('users/reset-password', async (data, thunkAPI) => {
	console.log(data);
	try {
		const refreshTime = localStorage.getItem('refreshTime');
		if (Date.now() > refreshTime) {
			await thunkAPI.dispatch(refresh());
		}

		const token = JSON.parse(localStorage.getItem('userToken'));

		const res = await axios.post(
			`https://api.fcform.shop/api/users/reset-password`,
			{
				email: data
			},
			{
				headers: {
					Authorization: `Bearer ${token}`
				}
			}
		);

		const resData = {
			data: res.data
		};

		return resData;
	} catch (error) {
		console.log(error);
		throw error.request.status;
	}
});
